import { mapGetters } from 'vuex';
import error from '@/mixins/plugin/error';

export default {
  data() {
    return {
      initialLogo: '',
      initialImages: []
    }
  },
  computed: {
    ...mapGetters({
      shopConfig: 'shop/config',
      userInfo: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
  },
  mixins: [error],
  async mounted () {
    const loading = this.$loading.show();
    await this.$store.dispatch('shop/getShopConfig', {
      subdomain: this.subdomain,
      shopId: this.shopId,
    });
    this.$loading.clear(loading);
    if (this.form) {
      this.initialLogo = this.shopConfig.logo?.url || '';
      this.initialImages = (this.shopConfig.images || []).map(item => item.url);
      this.form = {
        ...this.form,
        ...this.shopConfig,
        details: (this.shopConfig.details && this.shopConfig.details.length) ? this.shopConfig.details : [{ title: '', content: '' }],
        images: this.shopConfig.images || [],
        latLng: {
          lat: this.shopConfig.latLng?.latitude || 35.6762,
          lng: this.shopConfig.latLng?.longitude || 139.6503,
        }
      };
    }
  },
};
