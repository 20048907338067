<template>
  <section class="section">
    <div class="row">
      <div class="col">
        <Panel type="sheet">
          <template v-slot:headline>初期設定</template>
          <template v-slot:body>
            <div class="form">
              <section class="form-section">
                <FormRow :required="true">
                  <template v-slot:label>受付メールアドレスの登録</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      注文受付時に管理者側に対して、メールでお知らせすることができます。<br />
                      ※最大3つまで登録可能
                    </div>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.email1 || errors.receptions }"
                        type="text"
                        name="email"
                        v-email
                        v-model="form.receptions[0]"
                        v-trim
                        v-maxlength
                        maxlength="50"
                      />
                    </div>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.email2 || errors.receptions }"
                        type="text"
                        name="email"
                        v-email
                        v-model="form.receptions[1]"
                        v-trim
                        v-maxlength
                        maxlength="50"
                      />
                    </div>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.email3 || errors.receptions }"
                        type="text"
                        name="email"
                        v-email
                        v-model="form.receptions[2]"
                        v-trim
                        v-maxlength
                        maxlength="50"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>営業の状態</template>
                  <template v-slot:toggle>
                    <label class="form-toggle">
                      <input class="form-toggle-input" type="checkbox" name="openFlag" v-model="form.openFlag" />
                      <span class="form-toggle-set">
                        <span class="form-toggle-bg"></span>
                        <span class="form-toggle-circle"></span>
                      </span>
                    </label>
                  </template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      お店の営業状態を設定します。<br />
                      OFFにすると一時的に非表示にできます。
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>ヘッダー用アイコンをアップロードする</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <ul class="fileUpload">
                        <li class="fileUpload-item">
                          <Fileupload
                            @uploaded="setFileUpload"
                            @removed="removeFileUpload"
                            :loading="isFileLoading"
                            :initialUrl="initialLogo"
                          />
                        </li>
                      </ul>
                    </div>
                  </template>
                </FormRow>
              </section>
            </div>
          </template>
        </Panel>
      </div>
    </div>
  </section>
</template>

<script>
import { required, email, maxLength } from 'vuelidate/lib/validators';
//mixins
import shop from '@/mixins/module/shop';
// component
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';

export default {
  name: 'ShopSettingDefault',
  data: function() {
    return {
      form: {
        receptions: [],
        openFlag: false,
        logo: null,
      },
      isFileLoading: false,
    };
  },
  mixins: [shop],
  components: {
    FormRow,
    Fileupload,
  },
  validations: {
    form: {
      receptions: {
        required,
        $each: {
          email,
          maxLength: maxLength(50)
        }
      }
    }
  },
  created() {
    this.$parent.editPage = 'default';
  },
  methods: {
    async setFileUpload(url) {
      this.isFileLoading = true;
      this.form.logo = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this.isFileLoading = false;
    },
    removeFileUpload() {
      this.form.logo = '';
    },
    settingDefault() {
      this.$message.reset();
      this.$v.$touch();
      this.form.receptions = this.form.receptions.filter(item => item !== '');
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'shopSetting');
        return false;
      } else {
        const convertedForm = {
          logo: this.form.logo?.id || this.form.logo,
          openFlag: this.form.openFlag ? 1 : 0,
          receptions: this.form.receptions,
        }
        return convertedForm;
      }
    }
  },
};
</script>
